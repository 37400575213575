/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';
import Post from '../components/Blog/Post';
import Sidebar from '../components/Blog/Sidebar';
import Paginator from '../components/Paginator/Paginator';

export const pageQuery = graphql`
  query BlogIndexPageQuery($skip: Int!, $limit: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt

        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1110)
              }
            }
          }
        }

        author {
          node {
            uri
            name
          }
        }

        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`;

const BlogIndexPage = ({ data: { allWpPost }, pageContext }) => {
  const posts = allWpPost.nodes;

  return (
    <Layout>
      <Seo
        title="Blog | Bridge"
        description="Read breaking news and articles from Bridge Patient Portal."
      />
      <Section>
        <Container>
          <Row className="c-blog-header">
            <Col>
              <h1 className="text-center m-0">The Bridge Blog</h1>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col lg="7">
              {posts.map((post) => (
                <Post
                  key={post.uri}
                  uri={post.uri}
                  date={post.date}
                  author={post.author.node}
                  title={post.title}
                  featuredImage={post.featuredImage}
                  excerpt={post.excerpt}
                  categories={post.categories.nodes}
                />
              ))}
              <Row className="justify-content-center">
                <Col xs="auto">
                  <Paginator pageContext={pageContext} path="/blog/" />
                </Col>
              </Row>
            </Col>
            <Col lg="4">
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default BlogIndexPage;
